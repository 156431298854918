import React from "react";
import Layout from "../Layout";
import "./style.scss";

export default function TermsConditionsPage() {
  return (
    <Layout>
      <div className="privacypolicy">
        <div className="m_heading">
          <h2>Terms & Conditions</h2>
        </div>

        <div className="ppolicey_text">
          <p>
            These Terms and Conditions apply to all facilities hired to, and
            work done by Black Elephant Digital for, our clients unless
            otherwise expressly agreed in writing by us. By making a booking for
            Black Elephant Digital Studios, you acknowledge you have read and
            understood and accept these Terms and Conditions.
          </p>
        </div>
        <div className="s_heading">
          <h3>Our Facilities</h3>
        </div>
        <p>
          We will make the studio and studio engineer available to you for the
          duration of your agreed booking for your project. Only people directly
          involved in the project are permitted to come to the studio during
          your booking and we reserve the right to require any person not
          involved to leave the premises.
        </p>
        <div className="s_heading">
          <h3>Studio Booking Cost</h3>
        </div>
        <p>
          Depending on your project, full payment or a deposit is required to
          secure the booking, but the final invoice is generated on the basis of
          total hours of work and nature of the work. Until a booking is
          confirmed, and estimate generated, our rates, policies, and equipment
          may change without notice. It is your responsibility to set
          limitations on all expenses arising from any session you book. Any
          limitations on expenses should be clearly expressed to us before
          commencing any session. A service-specific charge will be added for
          each additional hour of studio use, and the approximate charges are{" "}
          <strong>Rs.1500 per hour, plus applicable taxes</strong>.
          <br />
          <br />
          <strong>
            We do not entertain walk-ins, as a strict policy. Upon payment of
            the booking fee, a slot for studio hire will be alloted to you.{" "}
          </strong>
        </p>

        <div className="s_heading">
          <h3>GST</h3>
        </div>
        <p>
          All prices quoted in our estimate are exclusive of Goods and Services
          Tax unless otherwise stated. GST of 18% will be added to the final
          invoice.
        </p>

        <div className="s_heading"> File Storage, Back Ups</div>
        <p>
          You may bring your own portable hard drives for the purpose of
          recording and backing-up your sessions and we will, upon request,
          provide information regarding correct hard drive specifications. Pen
          drives (Rs. 700 for 8GB) and hard drives are available as per actual
          cost, for the transfer of files. However, we take no responsibility
          for media that deteriorates over time, breaks, develops faults, or is
          in a format that becomes obsolete. You must promptly notify us in of
          any defect in or loss of or damage to any masters or other agreed work
          we do for you.
        </p>
        <div className="s_heading">Infringement of Intellectual Property</div>
        <p>
          Black Elephant Digital is not liable for work that infringes on
          copyrights/ intellectual property rights of any artist/ record or
          music label during the course of the project. The responsibility of
          any legal repercussions lie solely with the client, and any commercial
          use of digital assets generated are subject to copyright law as per
          the governing legal framework of India.
        </p>

        <div className="s_heading"> Final Handover </div>
        <p>
          We require payment in full before the completion of the project. No
          recorded media from a session will be turned over to you until
          outstanding charges for all completed studio work have been paid in
          full (including post or courier costs, pen drive or storage costs). We
          will retain a general lien on any master recordings and or materials
          in our possession for any unpaid balance you may owe to us. You will
          be liable for any fees, costs and expenses we incur for any data
          storage.
        </p>
      </div>
    </Layout>
  );
}
